<template>
	<div class="main-contents evl">
		<div class="tit">기술인재평가 노출 관리<template v-if="mainViewCnt"> / <span>현재 노출 : 9개</span></template></div>
		<div class="search-box">
			<SelectComp title="완료일순" v-model="input.orderDate" list="DSC:완료일 최근순,ASC:완료일 오래된순" :isAll="true" /><nbsp/>
			<SelectComp title="평가순"   v-model="input.orderScore" list="DSC:평가높은순,ASC:평가낮은순" :isAll="true" /><nbsp/>
			<SelectComp title="노출여부" v-model="input.mainViewYn" list="Y:노출중,N:노출중 제외" :isAll="true"/><nbsp/>
			<InputComp title="프로젝트명" v-model="input.searchTxt" @keyup.enter="getList('1')"/>
			<img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getList('1')">
			<div class="sub_btn" @click="evalOnOff('Y')">+ 메인화면 노출</div>
			<div class="sub_btn exit" @click="evalOnOff('N')">노출종료</div>
		</div>
		<!-- 1set -->
		<div class="list-box" v-for="item in list" :key="item.evalTabSeq">
			<SelectComp type="yn" :value="checks[item.evalTabSeq] ? 'Y':'N'" @change="checkItem(item)"/><nbsp/>
			<div class="prj_outline">
				<div class="left">
					<div class="exp_mark" v-if="item.mainViewYn == 'Y'">노출중</div>

					<div class="regi mr-5">
						<div class="outline" style="display: inline-flex;">
							<div class="label ivl10">프로젝트명</div>
							<div class="value prj">{{item.projectNm}}</div>
						</div>
						<div class="outline">
							<div class="label ivl03" style="margin-right: -3px;">개요</div>
							<div class="value text">{{item.projectDtlDesc}}</div>
						</div>
						<div class="outline">
							<div class="label ivl02">고 객 사</div>
							<div style="display: inline-flex; width: 620px;">
								<div class="prj_outline_clientname">
									{{item.corpNm}}
								</div>
								<div class="prj_outline_clientloc">
									<img src="/images/location.png" alt="위치" />{{item.projectLocCont}}
								</div>
							</div>							
						</div>
						<div class="outline">
							<div class="label ivl05">산업분야</div>
							<div class="value" style="display: inline-block !important;"><SelectComp type="text" cdId="PRO105" :value="item.indtypeFilterCd"/> / <SelectComp type="text" cdId="PRO143" :value="item.indtypeClassCd"/></div>
						</div>
					</div>
					<div class="int_box">
						<div class="outline">
							<div class="label ivl02">수 행 사</div>
							<div class="value">{{item.corpNm}}</div>
						</div>
						<div class="outline">
							<div class="label ivl02">담 당 자</div>
							<div class="value">{{item.corpMberNm}}</div>
						</div>
						<div class="outline">
							<div class="label">개 발 기 간</div>
							<div class="value">{{item.projectStartYyyymmdd | date('yyyy.MM.dd')}} - {{item.projectEndYyyymmdd | date('yyyy.MM.dd')}}</div>
						</div>
					</div>

					
				</div>
				<eval-div-grade tagNm="div" class="right" :value="item.evalItemDivGrade">
					<template v-slot:title><p>수요기업 평가</p></template>
					<template v-slot="slot">
						<div class="star-evl">
							<grade-img type="CLIENT_EVAL_CST" :grade="slot.item.grade"/>
							<SelectComp tagName="p" type="text" :value="slot.item.cd" cdId="PRO110"/>
						</div>
					</template>
				</eval-div-grade>
			</div>
			<!-- 프로젝트 평가 -->
			<div class="review">
				<div class="layout">
					<div class="photo">
						<ImageComp type="image" :src="'/api/mem/myp/corpImageDown?corpSeq=' + item.corpSeq" defaultSrc="PHOTO"/>
					</div>
					<div class="cstom_name">{{item.corpMberNm}}<span>{{item.corpLoginId}}</span></div>
				</div>
				<div class="layout">
					<div class="list cstom eval">
						<p>프로젝트 평가<span class="date">{{item.evalDate | date('yyyy.MM.dd')}}</span></p>
						<nbsp :value="item.overallOpinCont"/>
					</div>
				</div>
			</div>
		</div>

		<!-- 페이징부분 -->
		<paging-comp :pageInfo="pageInfo" @page-click="goPage"/>
	</div>
</template>

<script>
import pagingComp from '@/components/PagingComp.vue';
import gradeImg from "@/components/highpro/GradeImg.vue";
import evalDivGrade from '@/components/highpro/EvalDivGrade.vue';
export default {
	components: { pagingComp, gradeImg, evalDivGrade },
	data() {
		return {
			input:{
				serviceType: 'MAIN',
				evalYn:'Y',
				orderDate : '',
				orderScore: '',
				mainViewYn: '',
				searchType: 'PROJ',
				searchTxt: '',
				pageIndex: 1,
			},
			mainViewCnt: '',
			list: [],
			checks:{},
			pageInfo : {},
		};
	},
	beforeMount(){
		this.getList();
	},
	methods : {
        getList(div, isScroll = false){
			if(div) this.input.pageIndex = 1;
			
            this.$.httpPost('/api/mem/adm/evl/getEvalList', this.input)
                .then(res => {
					this.checks = {};
                    this.list = res.data.list;
                    this.pageInfo = res.data.pageInfo;
                    if(isScroll) {
						window.scroll(0, 0);
					}
                }).catch(this.$.httpErrorCommon);
        },
		goPage(page){
			this.input.pageIndex = page;
			this.getList();
		},
		goDetail(item){
			var query = {
				div : this.div,
				mdiv : this.mdiv
			}
			if(item) query.banner = item.bannerSeq;
			this.$router.push({name: this.$route.name.substr(0, 6) + 'M02', query});
		},
		checkItem(item){
			// console.log('checkItem start', item, Object.keys(this.checks).length, JSON.stringify(this.checks));
			var evalTabSeq = item.evalTabSeq;
			if(this.checks[evalTabSeq]){
				delete this.checks[evalTabSeq];
			} else {
				this.checks[evalTabSeq] = item;
			}
			this.$forceUpdate();
			// console.log('checkItem end', item, Object.keys(this.checks).length, JSON.stringify(this.checks));
		},
		evalOnOff(mainViewYn){
			// console.log('offBanner start', JSON.stringify(this.checks));
			if(this.list.length == 0){
				return alert('조회 된 평가가 없습니다.');
			} else if(Object.keys(this.checks).length == 0){
				return alert('선택 된 평가가 없습니다.');
			}
			var list = [];
			for(var key in this.checks){
				list.push(this.checks[key]);
			}

			this.$.popup('/adm/man/MAN903P01', {mainViewYn, list})
				.then(res => {
					if(res) {
						this.getList();
					}
				});

		}
	}
}
</script>